import templates from '../templates';
import utilities from '../utilities';
import PubSub from 'pubsub-js';
import BaseTemplate from "./BaseTemplate";

class Template extends BaseTemplate {
  constructor(id, settings) {
    super();

    this.settings = settings;
    this._videoTag = document.getElementById(id);
    this._wrapper = this.createElement(templates.WRAPPER.replace('{playerIdClass}', `player-id-${id}`));
    this._openAdLink = this.createElement(templates.OPEN_AD_LINK);
    this._skipButton = this.createElement(templates.SKIP_BUTTON);
    this._itvBanner = this.createElement(templates.ITV_BANNER);
    this._uvbBanner = this.createElement(templates.UVB_BANNER);
    this._settingsControl = this.createElement(templates.SETTINGS);
    this._settingsMenu = this.createElement(templates.SETTINGS_MENU);
    this._loader = this.createElement(templates.LOADER);
    this.refreshUVBBanner = null;
    this.setITVScale = this.setITVScale.bind(this);
    utilities.on(window, 'resize', this.setITVScale);

    this.hideItv = this.hideItv.bind(this);
    this.hideUvb = this.hideUvb.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.openSpeedsMenu = this.openSpeedsMenu.bind(this);
    this.backSpeedsMenu = this.backSpeedsMenu.bind(this);
    this.openQualitiesMenu = this.openQualitiesMenu.bind(this);
    this.backQualitiesMenu = this.backQualitiesMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.bus = PubSub
  }

  appendSource(source, clickHandler, isHls) {
    if (isHls) {
      const item = this.createElement(`<div class="ae-player__select-quality">${source.qualityLevel.height}p</div>`);
      if (source.qualityLevel.height >= 1080 && source.qualityLevel.height < 2160) {
        const quality = this.createElement(`<span>HD</span>`);
        quality.style.background = this.settings.controlsColor;
        item.appendChild(quality);
      }
      if (source.qualityLevel.height >= 2160) {
        const quality = this.createElement(`<span>4K</span>`);
        quality.style.background = this.settings.controlsColor;
        item.appendChild(quality);
      }
      if (clickHandler) utilities.on(item, 'click', clickHandler);
      this.settingsMenuQualityList.appendChild(item);
      return;
    }
    const item = this.createElement(`<div class="ae-player__select-quality">${source.label}</div>`);
    const quality = this.createElement(`<span>HD</span>`);
    if (parseInt(item.innerHTML, 10) >= 1080) {
      quality.style.background = this.settings.controlsColor;
      item.appendChild(quality);
    }
    if (clickHandler) utilities.on(item, 'click', e => clickHandler(e, source));
    this.settingsMenuQualityList.appendChild(item);
  }

  setItvSize() {
    const qty = this.itvBannerSpot.children.length;
    const margin = 5 * (qty - 1);
    const width = 300 * qty;
    const padding = 20;
    this.itvBanner.style.width = `${width + margin + padding}px`;
  }

  setITVScale() {
    try {
      const controlsH = this.controls.clientHeight + 40;
      const playerH = this.wrapper.clientHeight - controlsH;
      if (playerH > 0) {
        const scale = playerH < this.itvBanner.clientHeight ? playerH / this.itvBanner.clientHeight : 1;
        this.itvBanner.style.transform = `translate(-50%, -55%) scale(${scale})`;
      }
    } catch (e) {
    }
  }

  hideItv() {
    this.itvBanner.style.display = 'none';
  }

  showItv() {
    this.itvBanner.style.display = 'block';
    requestAnimationFrame(() => {
      if (!this.isAdPlaying() && this.settings.spots.itv.updateItvOnPause && !this.vjs.seeking()) {
        this.updateSpot('itv');
      }
      this.setITVScale();
    });
  }

  hideUvb() {
    if (this.videoContainer.contains(this.uvbBanner)) {
      this.videoContainer.removeChild(this.uvbBanner);
    }
    clearInterval(this.refreshUVBBanner);
  }

  showUvb() {
    if (utilities.isMobile() || !this.settings.spots.uvb.display || !this.settings.spots.uvb.code) {
      return;
    }
    this.videoContainer.appendChild(this.uvbBanner);
    this.updateSpot('uvb');
    this.setUVBRefreshing();
  }

  showSkipButton() {
    this.skipButton.classList.add('ae-player__skip--visible');
  }

  hideSkipButton() {
    this.skipButton.classList.remove('ae-player__skip--visible');
  }

  updateSpot(name) {
    this[`${name}BannerSpot`].innerHTML = '';
    this[`${name}BannerSpot`].innerHTML = this.settings.spots[name].code;
    this.bus.publish(`update-${name}`);
  }

  setUVBRefreshing() {
    this.refreshUVBBanner = setInterval(() => {
      this.updateSpot('uvb');
    }, this.settings.spots.uvb.refreshTime);
  }

  setColors() {
    this.progressBar.style.background = this.settings.controlsColor;
    if (!utilities.isMobile()) {
      this.itvBannerTitle.style.background = this.settings.spots.itv.style.header.background;
      this.itvBannerTitle.style.color = this.settings.spots.itv.style.header.color;
      this.itvBannerResumeButton.style.background = this.settings.spots.itv.style.resume.background;
      this.itvBannerResumeButton.style.color = this.settings.spots.itv.style.resume.color;
      this.itvBannerResumeButton.style.display = this.settings.spots.itv.style.resume.display ? 'flex' : 'none';
      this.itvBannerCloseButton.style.color = this.settings.spots.itv.style.close.color;
      this.itvBannerCloseButton.style.display = this.settings.spots.itv.style.close.display ? 'flex' : 'none';
    }
  }

  setBanners() {
    this.itvBannerSpot.innerHTML = this.settings.spots.itv.code;
    this.uvbBannerSpot.innerHTML = this.settings.spots.uvb.code;
  }

  moveCustomElementsToVideoWrapper() {
    this.videoContainer.appendChild(this.openAdLink);
    this.videoContainer.appendChild(this.skipButton);

    if (this.settings.showSettingsButton) {
      this.controls.insertBefore(this.settingsControl, this.controlsProgress);
    }

    this.videoContainer.appendChild(this.settingsMenu);
    this.videoContainer.appendChild(this.loader);
    if (this.settings.spots.itv.display && this.settings.spots.itv.code && !utilities.isMobile()) {
      this.videoContainer.appendChild(this.itvBanner);
    }
  }

  bindTemplateEvents() {
    utilities.on(this.itvBannerCloseButton, 'click', this.hideItv);
    utilities.on(this.itvBannerResumeButton, 'click', this.resume);
    utilities.on(this.uvbBannerClose, 'click', this.hideUvb);

    utilities.on(this.settingsControlButton, 'click', this.toggleMenu);

    utilities.on(this.settingsMenuSpeedOpen, 'click', this.openSpeedsMenu);
    utilities.on(this.settingsMenuSpeedBack, 'click', this.backSpeedsMenu);

    utilities.on(this.settingsMenuQualityOpen, 'click', this.openQualitiesMenu);
    utilities.on(this.settingsMenuQualityBack, 'click', this.backQualitiesMenu);

    utilities.on(this.skipButton, 'click', this.skip);

    if (utilities.isMobile()) {
      utilities.on(this.settingsClose, 'click', this.toggleMenu);
    }

    this.clickOutside = function (event) {
      var isClickInside = this.settingsMenu.contains(event.target) || this.settingsControl.contains(event.target) || this.settingsControlButton.contains(event.target);
      if (!isClickInside && this.wrapper.classList.contains('ae-player--menu-opened')) {
        this.toggleMenu()
      }
    }.bind(this);

    utilities.on(document, 'click', this.clickOutside);
  }

  adPlay() {
    return this._wrapper.classList.add('ae-player--is-ad-playing');
  }

  adStop() {
    return this._wrapper.classList.remove('ae-player--is-ad-playing');
  }

  isAdPlaying() {
    return this._wrapper.classList.contains('ae-player--is-ad-playing');
  }

  toggleMenu() {
    this.wrapper.classList.toggle('ae-player--menu-opened');
    this.settingsControl.classList.toggle('ae-player__settings--opened');
    this.settingsMenu.classList.toggle('ae-player__menu--visible');
  }

  openSpeedsMenu() {
    this.settingsMenu.classList.add('ae-player__menu--speed');
  }

  backSpeedsMenu() {
    this.settingsMenu.classList.remove('ae-player__menu--speed');
  }

  openQualitiesMenu() {
    this.settingsMenu.classList.add('ae-player__menu--quality');
  }

  backQualitiesMenu() {
    this.settingsMenu.classList.remove('ae-player__menu--quality');
  }

  setPlaybackRates() {
    if (this.settings.player.playbackRates.length < 2) {
      this.hidePlaybackRatesMenu();
      return;
    }
    this.settings.player.playbackRates.forEach(rate => {
      const item = this.createElement(`<div class="ae-player__select-speed">${rate}</div>`);
      utilities.on(item, 'click', this.selectPlaybackRate);
      if (rate === 'Normal') {
        item.style.color = this.settings.controlsColor;
      }
      this.settingsMenuSpeedList.appendChild(item);
    });
    this.settingsMenuSpeedValue.innerHTML = 'Normal';
  }

  selectPlaybackRate(e) {
    this.clearPlaybackRates(e.target);
    let rate = 1;
    if (e.target.innerHTML !== 'Normal') {
      rate = parseFloat(e.target.innerHTML);
    }
    e.target.style.color = this.settings.controlsColor;
    try {
      this.vjs.playbackRate(rate);
    } catch (e) {
    }
    this.settingsMenuSpeedValue.innerHTML = e.target.innerHTML;
    this.backSpeedsMenu();
  }

  clearPlaybackRates() {
    const items = this.settingsMenuSpeedList.children;
    for (let i = 0; i < items.length; i++) {
      items[i].removeAttribute('style');
    }
  }

  hidePlaybackRatesMenu() {
    this.settingsSpeedMenu.style.display = 'none';
    this.checkSettingsMenuButtonNeedles();
  }

  clearQualityLevels() {
    const items = this.settingsMenuQualityList.children;
    for (let i = 0; i < items.length; i++) {
      items[i].removeAttribute('style');
    }
  }

  showLoader() {
    this.loader.classList.add('ae-player__loader--visible');
  }

  hideLoader() {
    this.loader.classList.remove('ae-player__loader--visible');
  }

  showQualityLevels() {
    const el = this.settingsMenu.querySelector('.ae-player__quality')
    el.style.display = 'block';
  }

  hideQualityLevels() {
    const el = this.settingsMenu.querySelector('.ae-player__quality')
    el.removeAttribute('style')
  }

  insertAutoResolution() {
    const auto = this.createElement(`<div class="ae-player__select-quality" style="color: ${this.settings.controlsColor}">Auto</div>`);
    utilities.on(auto, 'click', function () {
      this.selectHLSQuality(null);
      this.saveQuality(-1);
    }.bind(this));
    this.settingsMenuQualityList.insertBefore(auto, this.settingsMenuQualityList.children[1]);
  }

  updateSettingsMenuQualityValue(value) {
    this.settingsMenuQualityValue.innerHTML = value;
  }

  mobileLayout() {
    if (!utilities.isMobile()) return;
    this.wrapper.classList.add('ae-player--mobile')
  }

  showNativeControls() {
    this.videoTag.classList.add('native-controls-showed');
  }

  showCustomControls() {
    this.videoTag.classList.add('custom-controls-showed');
  }

  enableControls(native) {
    if (native) {
      this.showNativeControls();
      return;
    }

    this.showCustomControls();
  }

  checkSettingsMenuButtonNeedles() {
    if (this.settingsMenu.offsetHeight === 0) {
      this.hideSettingsMenuButton();
    } else {
      this.showSettingsMenuButton();
    }
  }

  hideSettingsMenuButton() {
    this._wrapper.classList.add('settings-menu-hidden');
    // if (!this.settings.showSettingsButton) {
    // }
  }

  showSettingsMenuButton() {
    this._wrapper.classList.remove('settings-menu-hidden');
    // if (!this.settings.showSettingsButton) {
    // }
  }

  get savedSourceItem() {
    try {
      const savedQualityLevel = sessionStorage.getItem('ae-player-last-quality');
      if (savedQualityLevel) {
        return this.wrapper.querySelector(`source[size="${savedQualityLevel}"]`) || null;
      }
    } catch (e) {
      console.warn('session storage error', e);
      return null;
    }
  }

  get defaultSourceItem() {
    const sources = [...this.wrapper.querySelectorAll('source')];
    let maxSource = sources[0];

    sources.forEach(el => {
      if (parseInt(maxSource.getAttribute('size')) < parseInt(el.getAttribute('size'))) {
        maxSource = el;
      }
    })

    return maxSource;
  }

  get srcSource() {
    return this.wrapper.querySelector('video').getAttribute('src');
  }

  get wrapper() {
    return this._wrapper;
  }

  get openAdLink() {
    return this._openAdLink;
  }

  get skipButton() {
    return this._skipButton;
  }

  get skipButtonCount() {
    return this._skipButton.querySelector('.ae-player__skip-count');
  }

  get skipButtonValue() {
    return this._skipButton.querySelector('.ae-player__skip-value');
  }

  get loader() {
    return this._loader;
  }

  get itvBanner() {
    return this._itvBanner;
  }

  get itvBannerSpot() {
    return this.itvBanner.querySelector('.ae-player__itv-spot');
  }

  get itvBannerTitle() {
    return this.itvBanner.querySelector('.ae-player__itv-title');
  }

  get itvBannerResumeButton() {
    return this.itvBanner.querySelector('.ae-player__resume button');
  }

  get itvBannerCloseButton() {
    return this.itvBanner.querySelector('.ae-player__itv-title button');
  }

  get uvbBanner() {
    return this._uvbBanner;
  }

  get uvbBannerSpot() {
    return this.uvbBanner.querySelector('.ae-player__bottom-spot');
  }

  get uvbBannerClose() {
    return this.uvbBanner.querySelector('button');
  }

  get controls() {
    return this.wrapper.querySelector('.vjs-control-bar');
  }

  get controlsProgress() {
    return this.wrapper.querySelector('.vjs-progress-control');
  }

  get progressBar() {
    return this.wrapper.querySelector('.vjs-play-progress');
  }

  get videoTag() {
    return this._videoTag;
  }

  get videoContainer() {
    return this.wrapper.querySelector('.video-js');
  }

  get sources() {
    const sources = this.wrapper.getElementsByTagName('source');
    return [...sources];
  }

  get settingsControl() {
    return this._settingsControl;
  }

  get settingsControlButton() {
    return this.settingsControl.querySelector('.ae-player__settings-button');
  }

  get settingsMenu() {
    return this._settingsMenu;
  }

  get settingsClose() {
    return this.settingsMenu.querySelector('button');
  }

  get settingsMenuSpeedOpen() {
    return this.settingsMenu.querySelector('.ae-player__menu-open-speed');
  }

  get settingsMenuSpeedValue() {
    return this.settingsMenu.querySelector('.ae-player__menu-speed-value');
  }

  get settingsMenuSpeedList() {
    return this.settingsMenu.querySelector('.ae-player__menu-speed');
  }

  get settingsSpeedMenu() {
    return this.settingsMenu.querySelector('.ae-player__speed');
  }

  get settingsMenuSpeedBack() {
    return this.settingsMenu.querySelector('.ae-player__back--speed');
  }

  get settingsMenuQualityOpen() {
    return this.settingsMenu.querySelector('.ae-player__menu-open-quality');
  }

  get settingsMenuQualityValue() {
    return this.settingsMenu.querySelector('.ae-player__menu-quality-value');
  }

  get settingsMenuQualityList() {
    return this.settingsMenu.querySelector('.ae-player__menu-quality');
  }

  get settingsMenuQualityBack() {
    return this.settingsMenu.querySelector('.ae-player__back--quality');
  }

  destroy() {
    utilities.off(this.itvBannerCloseButton, 'click', this.hideItv);
    utilities.off(this.uvbBannerClose, 'click', this.hideUvb);

    utilities.off(this.settingsControlButton, 'click', this.toggleMenu);

    utilities.off(this.settingsMenuSpeedOpen, 'click', this.openSpeedsMenu);
    utilities.off(this.settingsMenuSpeedBack, 'click', this.backSpeedsMenu);

    utilities.off(this.settingsMenuQualityOpen, 'click', this.openQualitiesMenu);
    utilities.off(this.settingsMenuQualityBack, 'click', this.backQualitiesMenu);

    if (utilities.isMobile()) {
      utilities.off(this.settingsClose, 'click', this.toggleMenu);
    }

    utilities.off(window, 'resize', this.setITVScale);
    utilities.off(document, 'click', this.clickOutside);
  }
}

export default Template;
