import 'regenerator-runtime/runtime';
import videojs from 'video.js';
import 'videojs-contrib-quality-levels';
import 'videojs-hotkeys';
import './../plugins/volume-persister';
import defaultSettings from '../default-settings';
import {events} from '../types';
import utilities from '../utilities';
import Vast from './Vast';
import Template from './Template';
import '../../style/style.scss';
import Events from './Events';
import Redirect from './Redirect';
import Logger from './Logger';

class AEPlayer extends Template {
  constructor(id, vastUrl, settings) {
    super(id, settings);

    try {
      this.id = id;
      this.hlsFailed = false;
      this.settings = utilities.merge(defaultSettings, settings);
      this.guid = utilities.getGuid();

      if (!this.isSupported) {
        this.fallbackPlayer();
        return;
      }

      this.setupLogger(settings);
      this.videoTag.controls = this.settings.player.controls;
      this.videoTag.classList.add('video-js');
      this.videoTag.parentNode.appendChild(this.wrapper);
      this.mobileLayout();
      this.wrapper.appendChild(this.videoTag);
      this.resume = this.resume.bind(this);
      this.selectPlaybackRate = this.selectPlaybackRate.bind(this);
      this.onVideoTrackerUpdate = this.onVideoTrackerUpdate.bind(this);

      this.applyPreloadVideoConfig();
      this.checkSettingsMenuButtonNeedles();

      this.vjs = videojs(this.videoTag, this.settings.player, () => {
        this.moveCustomElementsToVideoWrapper();
        this.setColors();
        this.setBanners();
        this.bindTemplateEvents();

        this.bindPlayerEvents();
        this.setItvSize();

        this.setSources();

        this.setPlaybackRates();
        this.setQualityLevels();

        window.addEventListener('storage', this.onVideoTrackerUpdate);

        this.vjs.persistVolume && this.vjs.persistVolume();

        this.vjs.controlBar.removeChild('PictureInPictureToggle');
        this.enableControls(this.vjs.usingNativeControls_);

        if (!utilities.isMobile()) {
          this.vjs.hotkeys({
            enableVolumeScroll: false,
            seekStep: () => {
              if (this.isAdPlaying()) return 0;
              return 5;
            }
          });
        }

        requestAnimationFrame(this.setITVScale.bind(this));
      });

      this.bindSpritePreview();

      this.vast = new Vast(id, vastUrl, this.settings, {
        vjs: this.vjs,
        vjsSrc: this.vjsSrc.bind(this)
      }, [this.savedSource], this);
      this.ga = new Events(settings);
    } catch (e) {
      this.fallbackPlayer();
      this.logger && this.logger.captureEvent(new Error(`
        player initialization failed. \n
        message: ${e.message}
      `));
    }
  }

  bindSpritePreview() {
    if (this.settings.preview) {
      import('./../plugins/sprite-plugin').then(() => {
        this.vjs.spriteThumbnails(this.settings.preview);
      });
    }
  }

  bindPlayerEvents() {
    this.vjs.on('ready', () => {
      this.bus.publish('update-itv');
    });
    const onError = () => {
      const isMp4Movie = !this.vast.hasAd && !this.isHls();
      const isHlsMovie = !this.vast.hasAd && this.isHls();
      const isAdMovie = this.vast.hasAd;
      const error = this.vjs.error();
      const {code: errorCode} = error;
      // this.logger && this.logger.captureEvent(new Error(`
      //   VIDEOJS: ERROR: CODE: ${errorCode} \n
      //   MESSAGE: ${error.message} \n
      //   SRC: ${this.vjsSrc()}
      // `));

      if (isAdMovie) {
        this.ga.sendNonInteractive('video', 'error', 'vast', errorCode);
        this.vast.recover();
        this.vjs.one('error', onError);
        return;
      }

      if (isHlsMovie) {
        this.hlsFailed = true;
        this.setSources(true);
        this.vjs.play();
        this.ga.sendNonInteractive('video', 'error', 'hls', errorCode);
        this.vjs.one('error', onError);
      }

      if (isMp4Movie) {
        this.ga.sendNonInteractive('video', 'error', 'mp4', errorCode);
      }
    };
    this.vjs.one('error', onError);
    this.vjs.on('fullscreenchange', this.onFullscreenChange.bind(this));
    this.vjs.on('play', this.onPlay.bind(this));
    this.vjs.on('pause', this.onPause.bind(this));
    this.vjs.on('ended', this.onEnded.bind(this));
    this.vjs.on('seeked', () => this.wrapper.classList.remove('ae-player--is-pause-button'));
    events.forEach(event => {
      this.vjs.on(event, data => {
        this.bus.publish(event, data)
      });
    });
  }

  async onPlay() {
    if (!this.vjs.paused) return;
    this.hideItv();
    this.gaSendPlayEvent();
    if (this.vast.isInitialVast()) {
      this.showLoader();
      this.vjs.pause();
      try {
        await this.vast.loadVast();
      } catch (e) {
        this.vast.brokenVast = true;
        this.vast.recover();
      }
    }
    try {
      localStorage.setItem('ae-player-current-video', this.guid);
    } catch (e) {
      console.warn('localStorage does not support with:' + e);
    }
    this.vjs.one('loadedmetadata', () => {
      this.hideLoader();
    });
    this.vjs.one('error', () => {
      this.showExternalGalleryLink();
    });
  }

  onPause() {
    if (this.vast.isInitialVast()) {
      return;
    }
    if (this.vjs.seeking()) {
      this.wrapper.classList.add('ae-player--is-pause-button');
    }
    if (this.isAdPlaying()) {
      this.vast.vastTracker.setPaused(true);
    }
    if (!this.vjs.seeking() && !this.isAdPlaying() && !utilities.isMobile()) {
      this.showItv();
    }
  }

  async onEnded() {
    if (this.vast.hasAd) {
      this.vast.recover();
      this.vast.vastTracker.complete();
    } else {
      this.showItv();
    }
  }

  onFullscreenChange() {
    if (this.vjs.isFullscreen() === true) {
      !this.isAdPlaying() ? this.showUvb() : this.vast.vastTracker.setFullscreen(true);
    } else {
      this.hideUvb();
      if (this.isAdPlaying()) {
        this.vast.vastTracker.setFullscreen(false);
      }
    }
  }

  onVideoTrackerUpdate(event) {
    // if (event.key === 'ae-player-current-video' && event.newValue !== this.guid) {
    //   this.vjs.pause();
    // }
  }

  vjsSrc() {
    const filteredArgs = ([...arguments] || []).filter(Boolean);
    if (filteredArgs.length > 0) {
      const result = this.vjs.src.apply(this.vjs, filteredArgs);

      this.logger && this.logger.addBreadcrumb({
        category: 'source-change',
        message: `source was changed to ${this.vjs.src()}`,
        level: 'info',
        data: filteredArgs
      });

      return result;
    }

    return this.vjs.src();
  }

  setSources(noHls) {
    const sources = this.getSources(noHls);

    if (!noHls) {
      const levels = this.vjs.qualityLevels();
      let addedLevels = []

      levels.on('addqualitylevel', event => {
        if (!event.qualityLevel.width) return;

        if (addedLevels.includes(event.qualityLevel.height)) return;
        addedLevels.push(event.qualityLevel.height);

        const idx = levels.length-1;

        this.appendSource(event, () => {
          this.selectHLSQuality(event, idx);
          this.saveQuality(event && event.qualityLevel && event.qualityLevel.height || -1);
        }, true);

        try {
          const savedQualityLevel = parseInt(sessionStorage.getItem('ae-player-last-quality'));
          if (levels[levels.selectedIndex_] && savedQualityLevel === levels[levels.selectedIndex_].height) return;

          if (savedQualityLevel && savedQualityLevel === event.qualityLevel.height) {
            this.selectHLSQuality(event, idx);
          }
        } catch (e) {
          return null;
        }

        this.checkHlsQualityLevelsNeedles();
        this.checkSettingsMenuButtonNeedles(); // проверка показывать ли кнопку при добавлении разрешений
      });
    }

    if (this.savedSource && (noHls || !this.isHls())) {
      this.vjsSrc([this.savedSource]);
      this.selectQuality(this.savedSource);
    } else {
      this.vjsSrc(sources);
    }

    this.checkHlsQualityLevelsNeedles();
    this.checkSettingsMenuButtonNeedles(); // проверка показывать ли кнопку по дефолту
  }

  resume() {
    this.vjs.play();
  }

  getSources(noHls) {
    if (this.isHls() && !noHls) {
      const stream = this.sources.find(file => file.type === 'application/x-mpegURL');
      if (stream) {
        return [{
          src: stream.src,
          type: stream.type
        }];
      }
    }
    let sources = [];
    for (let i = 0; i < this.sources.length; i++) {
      const label = this.sources[i].getAttribute('size');
      const type = this.sources[i].type;
      let source = {
        src: this.sources[i].src,
        size: parseInt(label)
      };
      if (label) source.label = `${label}p`;
      if (type) source.type = type;
      sources.push(source);
    }
    if (noHls) {
      return sources.filter(file => file.type !== 'application/x-mpegURL');
    }

    return sources.sort((a, b) => a.size < b.size ? 1 : -1);
  }

  applyPreloadVideoConfig() {
    if (this.isHls() && document.visibilityState === 'visible') return;

    this.settings.player.preload = this.settings.videoPreload || 'metadata';
  }

  setQualityLevels() {
    if (!this.isHls()) {
      this.setMp4QualityLevels();
    }
  }

  gaSendPlayEvent() {
    if (this.vast.isInitialVast() || this.vast.isGoing) return;
    if (this.isHls()) {
      this.ga.sendHLSEvent();
    } else {
      this.ga.sendMP4Event();
    }
  }

  checkHlsQualityLevelsNeedles() {
    const levels = this.vjs.qualityLevels();

    if (levels.length < 2) {
      this.hideQualityLevels();
      this.checkSettingsMenuButtonNeedles();
      return;
    }

    if (levels.length > 1) {
      this.showQualityLevels();
      this.showSettingsMenuButton();
    }

    if (levels.length === 2) {
      this.insertAutoResolution();
    }
  }

  setMp4QualityLevels() {
    const sources = this.getSources();
    if (sources.length > 1) {
      sources.forEach(source => {
        this.appendSource(source, (e, source) => {
          this.selectQuality(source);
          this.saveQuality(source.size);
        });
      });
    } else {
      this.hideSettingsMenuButton();
    }
  }

  selectHLSQuality(event, idx = -1) {
    let levels = this.vjs.qualityLevels();
    let label = 'Auto';

    if (event) {
      label = event.qualityLevel.height + 'p';
    }

    levels.selectedIndex_ = idx;
    levels.trigger({type: 'change', selectedIndex: idx});
    this.clearQualityLevels();
    this.updateSettingsMenuQualityValue(label);
    this.backQualitiesMenu();
  }

  selectQuality(source) {
    const currentTime = this.isAdPlaying() ? 0 : this.vjs.currentTime();
    const isPlaying = !this.vjs.paused();
    this.vjsSrc(source.src);
    this.vjs.currentTime(currentTime);
    this.updateSettingsMenuQualityValue(source.label);
    this.backQualitiesMenu();
    if (isPlaying) {
      this.vjs.play();
    }
  }

  saveQuality(quality) {
    try {
      sessionStorage.setItem('ae-player-last-quality', quality);
    } catch (e) {
      console.warn('session storage error:', e);
    }
  }

  isHls() {
    if (this.hlsFailed) return false;
    const streams = this.sources.filter(item => item.type === 'application/x-mpegURL');
    return streams.length > 0;
  }

  on(event, callback) {
    this.bus.subscribe(event, callback);
  }

  get isSupported() {
    return !(utilities.checkJerkIE());
  }

  get savedSource() {
    const sourceItem = this.savedSourceItem || this.defaultSourceItem
    if (sourceItem) {
      return {
        size: sourceItem.getAttribute('size'),
        label: sourceItem.getAttribute('size') + 'p',
        type: sourceItem.getAttribute('type'),
        src: sourceItem.getAttribute('src')
      };
    }

    if (this.srcSource) {
      return {
        src: this.srcSource
      }
    }

    return null;
  }

  showExternalGalleryLink() {
    if (!this.settings.externalUrl) return;

    new Redirect({
      ...this.settings,
      videoContainer: this._wrapper,
      newWindowUrl: this.settings.externalUrl
    }).showGoButton();
  }

  setupLogger(settings) {
    try {
      if (settings.hasOwnProperty('disableSentry')) {
        Logger.enabled = !settings.disableSentry;
      }

      this.logger = Logger.getInstance();
    } catch (e) {}
  }

  fallbackPlayer() {
    const player = document.getElementById(this.id);
    if (player) {
      player.controls = 'true';
    }
  }

  destroy() {
    window.removeEventListener('storage', this.onVideoTrackerUpdate);
    utilities.off(this.itvBannerResumeButton, 'click', this.resume);
    videojs.getPlayer(this.id).dispose();
    this.vast.destroy();
    super.destroy();
  }
}

window.AEPlayer = AEPlayer;
export default AEPlayer;
